"use client";
import { Text } from "@chakra-ui/layout";
import { getImageProps } from "next/image";

import { ImageTransformationKeys } from "@/types/files/files.types";
import { Award } from "@/types/home/home.types";
import { directusImageLoader } from "@/utils/loaders";

import Styles from "./AwardsSection.module.scss";

type Props = {
  awards: Award[];
  imageKeys: ImageTransformationKeys;
};
const AwardSection = ({ awards, imageKeys }: Props) => {
  const commonImgProps = { alt: "award", width: 150, height: 190, loader: directusImageLoader };

  return (
    <>
      <div className={Styles.awardSectionWrapper}>
        {awards?.map((award, index) => {
          const {
            props: { srcSet: mobile },
          } = getImageProps({
            ...commonImgProps,
            src: `${award.image}?key=${imageKeys.mobileKey}`,
          });
        
          const {
            props: { srcSet: desktop, ...imgProps },
          } = getImageProps({
            ...commonImgProps,
            src: `${award.image}?key=${imageKeys.desktopKey}`,
          });

          return (
          <div className={Styles.awardItem} key={index}>
            <div className={Styles.award}>
              <picture>
                <source media="(max-width: 575px)" srcSet={mobile} />
                <source media="(min-width: 576px)" srcSet={desktop} />
                <img {...imgProps} alt="award" />
              </picture>
              <Text className={Styles.awardText}>{award.text}</Text>
            </div>
          </div>
          )
        }
      )}
      </div>
    </>
  );
};

export default AwardSection;
