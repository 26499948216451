import { atom } from 'recoil';

import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { NewCarData } from '@/types/new-cars/new-cars.types';
import { Vehicle } from '@/types/used-cars/used-cars.types';

export interface NewCarItem {
  type: 'new-car';
  newCar: NewCarData;
  mmCode?: string;
  source?: string;
}

export interface UsedCarItem {
  type: 'used-car';
  usedCar: Vehicle;
}

export interface SpecialItem {
  type: 'special';
  special: SpecialDetails;
  leadType: LeadFormType;
}

export const drawerState = atom<boolean>({
  key: 'drawerState',
  default: false,
});

export const floatingCTAShowState = atom<boolean>({
  key: 'floatingCTAShowState',
  default: true,
});

export const formConfigState = atom<{
  formName: string;
  leadType: LeadFormType;
}>({
  key: 'floatingCTAConfigState',
  default: { formName: 'General', leadType: 'general' },
});

export const formItemState = atom<
  UsedCarItem | NewCarItem | SpecialItem | undefined
>({
  key: 'floatingCTAItemState',
  default: undefined,
});

export const pageFormItemState = atom<
  UsedCarItem | NewCarItem | SpecialItem | undefined
>({
  key: 'floatingCTAPageItemState',
  default: undefined,
});

export type DisplayMode = 'mobile' | 'desktop' | 'all';

export interface SecondaryFloatingButtonBase {
  text: string;
  display: DisplayMode;
  colors?: {
    desktop?: {
      background: string;
      text: string;
      hoverBackground?: string;
      hoverText?: string;
    };
    mobile?: {
      background: string;
      text: string;
      hoverBackground?: string;
      hoverText?: string;
    };
  };
}

export type SecondaryFloatingButton =
  | (SecondaryFloatingButtonBase & { url: string; onClick?: never })
  | (SecondaryFloatingButtonBase & { onClick: () => void; url?: never });

export const secondaryFloatingButtonState =
  atom<SecondaryFloatingButton | null>({
    key: 'secondaryFloatingButtonState',
    default: null,
  });
