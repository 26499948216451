'use client';

import { Suspense } from 'react';

import {
  FeaturedItem,
  HomePage as HomePageType,
} from '@/types/home/home.types';
import { NewCarData, NewCarOverview } from '@/types/new-cars/new-cars.types';

import DiscoverySection from '../discovery/DiscoverySection';
import FancyCarousel from '../fancy-carousel/FancyCarousel';
import NewVehiclePromotion from '../new-vehicle-promotion/NewVehiclePromotion';

import AwardsSection from './AwardsSection';
import CTASection from './CTASection';
import HeroBanner from './HeroBanner';

interface Props {
  config: { [key: string]: any };
  data: HomePageType;
  featuredItems: FeaturedItem[];
  heroBanners: any;
  newCarsWithOverview?: NewCarOverview[];
  newCars?: NewCarData[];
}

function HomePage({
  config,
  data,
  featuredItems,
  heroBanners,
  newCarsWithOverview,
  newCars,
}: Props) {
  return (
    <div data-cy="home-page">
      <HeroBanner
        imageKeys={{
          desktopKey: 'image-1440-680',
          mobileKey: 'image-430-305',
        }}
        heroBanners={heroBanners}
        newCars={newCars ? newCars : []}
      />

      <Suspense fallback={null}>
        <NewVehiclePromotion
          imageKeys={{
            desktopKey: 'image-400-550',
            mobileKey: 'image-300-412',
          }}
          featuredItems={featuredItems}
          newCarsWithOverview={newCarsWithOverview}
        />

        <FancyCarousel
          imageHrefBase="/new-cars"
          backgroundImageKeys={{
            desktopKey: 'image-1440-500',
            mobileKey: 'image-360-360',
          }}
          imageKeys={{
            desktopKey: 'image-530-270',
            mobileKey: 'image-270-137',
          }}
          carsWithOverview={newCarsWithOverview}
          body_types={config.new_car_body_types}
          new_cars_background={data?.new_cars_background}
        />

        <CTASection
          call_to_actions={data?.call_to_actions}
          imageKeys={{
            desktopKey: 'image-640-360',
            mobileKey: 'image-640-360',
          }}
        />
        <AwardsSection
          awards={data?.awards}
          imageKeys={{
            desktopKey: 'image-150-190',
            mobileKey: 'image-150-190',
          }}
        />
        <DiscoverySection
          footer_call_to_action={
            data?.footer_call_to_action ? data?.footer_call_to_action : null
          }
          footer_call_to_action_image={data?.footer_call_to_action_image}
        />
      </Suspense>
    </div>
  );
}
export default HomePage;
